import React from 'react'
import Background from '../components/background'
import styled from 'styled-components'
import Logo from '../components/logo'
import SearchBar from '../components/search-bar'
import PopularTopics from '../components/popular-topics'
import SocialMedia from '../components/social-media'
import GlobalStyle from '../components/global-style'
import usePopularTopics from '../hooks/usePopularTopics'
import SEO from '../components/seo'
import Refresh from '../components/refresh'

export default function SearchPage() {
  const [popularTopics, refreshPopularTopics] = usePopularTopics()

  return (
    <>
      <noscript>
        <NoScript>
          Your browser does not support JavaScript!
          Please view in another browser .
          <div>https://www.wholelawyers.com</div>
        </NoScript>
      </noscript>
      <Background />
      <GlobalStyle />
      <SEO title="AILaw" />
      <Container>
        <Logo size="large" />
        <SearchBar />
        <PopularTopicsContainer>
          <div style={{ alignSelf: 'flex-end' }}>
            <Refresh onClick={refreshPopularTopics} />
          </div>
          <PopularTopics source={popularTopics} />
        </PopularTopicsContainer>
        <Footer>
          <SocialMedia />
        </Footer>
      </Container>
    </>
  )
}

const Container = styled.div`
  max-width: 620px;
  width: 80%;
  align-self: center;
  padding-top: 10vh;
  padding-bottom: 40px;
  align-items: center;

  > * + * {
    margin-top: 48px;
  }
`

const Footer = styled.footer``

const PopularTopicsContainer = styled.div`
  min-height: 50vh;
`

const NoScript = styled.div`
  background:red;
  color:white;
  font-size: 18px;
`
